export interface DelayUsedCarSearchType {
  requestCode: string | null;
  requestType: string | null;
  requestDate: string | null;
  applyType: string | null;
  status: string | null;
  newVinNo: string | null;
  vinNo: string | null;
  current: number | 0;
  size: number | 20;
  dealerCode: string | null;
}
export const StatusListCnCheck = {
  "1": "已提交",
  "2": "区域已审批",
  "3": "区域已拒绝",
  "4": "SIPO已审批",
  "5": "SIPO已拒绝",
};

export const requestListCnCheck = {
  "1": "过期申请",
};

export const applyListCnCheck = {
  "1": "CPO销售",
  "2": "置换",
};
